const MicroModal = require('micromodal'); // commonjs module
const modals = document.querySelectorAll('.modal');

if (modals) {
    MicroModal.init({
		disableScroll: true,
		onShow: modal => {
			modalOpen(modal)
		},
		onClose: modal => {
			modalClose(modal)
		}
	});

	function modalOpen(modal) {
		const video = modal.querySelector('iframe');

		video.contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', '*');
	}

	function modalClose(modal) {
		const video = modal.querySelector('iframe');

		video.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
	}
}
