"use strict"
import './components/config'
import './components/header'
import './components/lazy'
import './components/sliders'
import './components/tabs'
import './components/modal'
import './components/animations'
import './components/count'
import './components/banner-video'
