const Flickity = require('flickity');
const timelines = document.querySelectorAll('.timelineContainer');

if (timelines) {

	timelines.forEach( (item) => {
		const slider = item.querySelector('.timelineSlider');
		const sliderPrev = item.querySelector('.sliderPrev');
		const sliderNext = item.querySelector('.sliderNext');

		let flkty = new Flickity( slider, {
			accessibility: true,
			prevNextButtons: false,
			pageDots: true,
			imagesLoaded: true,
			cellAlign: 'left',
			on: {
				ready: function() {
					setSliderHeightToMax(this);
				}
			}
		})

		sliderPrev.addEventListener( 'click', () => {
			flkty.previous();
		});

		sliderNext.addEventListener( 'click', () => {
			flkty.next();
		});

		window.addEventListener('load', () => {
			flkty.resize();
		});

		window.addEventListener('resize', () => {
			setSliderHeightToMax(flkty);
		});

		function setSliderHeightToMax(slider) {
			slider.cells.forEach(cell => cell.element.style.height = '');

			let heights = slider.cells.map(cell => cell.element.offsetHeight),
				max = Math.max.apply(Math, heights);

			slider.cells.forEach(cell => cell.element.style.height = max + 'px');
		}
	})
}
