const bannerTriggers = document.querySelectorAll('.bannerTrigger');

if (bannerTriggers) {

	bannerTriggers.forEach( (item) => {
		const bannerVideo = item.nextElementSibling;
		const text = item.querySelector('.sr-only');

		item.addEventListener('click', () => {
			item.classList.toggle('is-paused');

			if (bannerVideo.paused) {
				bannerVideo.play();
			} else {
				bannerVideo.pause();
			}

			if (text.innerHTML === 'Pause') {
				text.innerHTML = 'Play';
			} else {
				text.innerHTML = 'Pause';
			}
		})

		window.addEventListener('load', () => {
			bannerVideo.classList.add('is-loaded');
		})
	})
}
